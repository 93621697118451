// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-templates-producto-js": () => import("./../../../src/templates/producto.js" /* webpackChunkName: "component---src-templates-producto-js" */)
}

